














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'EmptySearchState',
})
export default class EmptySearchState extends Vue {
  @Prop({ required: false, default: '40px' }) width!: string;
}
